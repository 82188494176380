var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "musical-keys" },
    [
      _c(
        "div",
        { staticClass: "musical-keys__form" },
        [_c("MusicalKeysForm")],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "musical-keys-grid-transition", mode: "out-in" } },
        [
          _vm.isGridVisible
            ? _c(
                "div",
                { staticClass: "musical-keys__grid" },
                [_c("MusicalKeysUsersGrid")],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }