













import {
  computed,
  defineComponent,
  onBeforeUnmount,
  ref,
  watch
} from "@vue/composition-api"

import ModeSlideTransition from "@/components/GroupTeams/Common/GameModes/ModeSlideTransition.vue"
import useMusicalKeysRef from "./useMusicalKeysRef"
import useMusicalKeysState from "./useMusicalKeysState"
import MusicalKeysForm from "./MusicalKeysForm.vue"
import MusicalKeysUsersGrid from "./MusicalKeysUsersGrid.vue"

import { Media } from "@/components/GroupTeams/Common/SmartMedia.vue"

export default defineComponent({
  name: "MusicalKeys",
  components: {
    MusicalKeysUsersGrid,
    ModeSlideTransition,
    MusicalKeysForm
  },
  setup() {
    const { dbRef } = useMusicalKeysRef()
    const { updateState, isGridVisible } = useMusicalKeysState()

    watch(
      dbRef,
      (newValue, oldValue) => {
        oldValue?.off("value", updateState)
        newValue.on("value", updateState)
      },
      { immediate: true }
    )

    onBeforeUnmount(() => {
      dbRef.value.off("value", updateState)
    })

    return { isGridVisible }
  },
  created() {
    Media.add(this._uid)
  },
  beforeDestroy() {
    Media.rm(this._uid)
  }
})
